import React, { useCallback, useEffect, useState } from 'react';
import c from './App.module.scss';
import Pusher from 'pusher-js';
import _ from 'lodash';
import MapContainer from './MapContainer';

const pusher = new Pusher('8ddae66fddf871a6ce47', {
  cluster: 'eu',
});

export interface Device {
  deviceId: string;
  clientName: string;
  color: string;
  coordinates: number[];
}

export interface DealershipEvent {
  coordinates: number[];
  color: string;
  clientName: string;
  screenMode: string;
  fake: boolean;
  id: string;
}

interface Event {
  deviceId: string;
  clientName: string;
  screenMode: string;
  id: string;
  color?: string;
  location?: { lat: number; long: number };
}

function App() {
  const [visibleDealershipEvents, setVisibleDealershipEvents] = useState<DealershipEvent[]>([]);

  const plingPlongCallback = useCallback(
    (newEvent: Event) => {
      const device = devices.find((d) => d.deviceId === newEvent.deviceId);
      
      const color = newEvent.color ?? device?.color;
      const coordinates =!!newEvent.location ? [newEvent.location.long, newEvent.location.lat] : device?.coordinates; 
      
      if (!color || !coordinates) return;
      
      setVisibleDealershipEvents((prevState) => [
        ...prevState,
        {
          coordinates,
          color,
          clientName: newEvent.clientName ?? device?.clientName ?? "Ukjent",
          screenMode: newEvent.screenMode === 'Configurator' ? 'Nybil' : newEvent.screenMode,
          fake: newEvent.id.length < 6,
          id: newEvent.id,
        },
      ]);
      setTimeout(() => {
        setVisibleDealershipEvents((prevState) => prevState.filter((d) => d.id !== newEvent.id));
      }, 5 * 60 * 1000);
    },
    [setVisibleDealershipEvents]
  );

  useEffect(() => {
    if (!pusher) return;

    const channel = pusher.subscribe('cache-pling-plong');

    channel.bind('pling-plong', plingPlongCallback);

    return () => {
      pusher.unbind('pling-plong', plingPlongCallback);
      pusher.unsubscribe('cache-pling-plong');
    };
  }, [plingPlongCallback]);

  return (
    <div className={c.test}>
      <button
        className={c.testButton}
        onClick={() => {
          const randomDevice = devices[_.random(devices.length - 1)];

          plingPlongCallback({
            clientName: randomDevice.clientName,
            deviceId: randomDevice.deviceId,
            screenMode: _.get(['Nybil', 'Bruktbil'], _.random(1)),
            id: _.uniqueId().toString(),
            color: randomDevice.color,
            location: {long: randomDevice.coordinates[0], lat: randomDevice.coordinates[1]},
          });
        }}
      >
        <img
          src="https://res.cloudinary.com/hassel-software-as/image/upload/v1635159412/Short%20Shoft/SVG/Colored_primary_logo.svg"
          alt="Short Shift Logo"
          className={c.ssLogo}
        />
      </button>
      <div>
        <MapContainer visibleDealershipEvents={visibleDealershipEvents} />
      </div>
      <ul className={c.eventsList}>
        {visibleDealershipEvents.map((d) => (
          <li key={d.id}>{`${d.clientName} - ${d.screenMode} - ${d.fake ? '🔧' : '✔️'}`}</li>
        ))}
      </ul>
    </div>
  );
}

export default App;

const devices: Device[] = [
  {
    deviceId: '22e84fd0-59fbb030',
    clientName: 'Short Shift',
    coordinates: [10.746797, 59.910774],
    color: '#165FFB',
  },
  {
    deviceId: 'e8e5612-e44b020e',
    clientName: 'Short Shift',
    coordinates: [10.746797, 59.910774],
    color: '#165FFB',
  },
  {
    deviceId: 'ca053cdb-2901700e',
    clientName: 'Hyundai Hovedkontor',
    coordinates: [10.8316227, 59.9316129],
    color: '#1A2C5D',
  },
  {
    deviceId: '99b19239-882e2e95',
    clientName: 'Short Shift',
    coordinates: [10.746797, 59.910774],
    color: '#165FFB',
  },
  {
    deviceId: '2ac9be81-7dc3bf9b',
    clientName: 'Centralgaragen',
    coordinates: [10.1738495, 59.748334],
    color: '#0B1E26',
  },
  { deviceId: '2ac9be9e-5e98a54e', clientName: 'Frydenbø', coordinates: [5.230024, 60.380727], color: '#35DCC3' },
  { deviceId: '2ac9bea0-2b4f3fba', clientName: 'Frydenbø', coordinates: [8.1289714, 58.174678], color: '#35DCC3' },
  {
    deviceId: '2ac9becd-78f739cb',
    clientName: 'Frydenbø',
    coordinates: [7.1001102, 61.2260773],
    color: '#35DCC3',
  },
  {
    deviceId: '2acb8aa7-fbfdce13',
    clientName: 'Centralgaragen',
    coordinates: [10.1738495, 59.748334],
    color: '#0B1E26',
  },
  {
    deviceId: '2acb8fd8-b9da37f2',
    clientName: 'Short Shift',
    coordinates: [10.746797, 59.910774],
    color: '#165FFB',
  },
  {
    deviceId: '2acb8fe8-83493e63',
    clientName: 'Centralgaragen',
    coordinates: [10.1738495, 59.748334],
    color: '#0B1E26',
  },
  {
    deviceId: '2acb9014-8c298b5a',
    clientName: 'Centralgaragen',
    coordinates: [10.1738495, 59.748334],
    color: '#0B1E26',
  },
  {
    deviceId: '2acb9037-ff7115d0',
    clientName: 'Frydenbø',
    coordinates: [5.8865328, 61.4461781],
    color: '#35DCC3',
  },
  {
    deviceId: '2acb9047-6165119f',
    clientName: 'Frydenbø',
    coordinates: [7.4664959, 58.0203576],
    color: '#35DCC3',
  },
  {
    deviceId: '61318805-7f7bad98',
    clientName: 'Berge & Co',
    coordinates: [6.7258411, 61.9051518],
    color: '#A60053',
  },
  {
    deviceId: '6131882f-7f7badb1',
    clientName: 'Bilservice',
    coordinates: [10.179686, 59.139604],
    color: '#24618E',
  },
  {
    deviceId: '61318845-7f7badb3',
    clientName: 'Berge & Co',
    coordinates: [7.1001102, 61.2260773],
    color: '#A60053',
  },
  {
    deviceId: '6131884e-7f7bad93',
    clientName: 'Berge & Co',
    coordinates: [5.8865328, 61.4461781],
    color: '#A60053',
  },
  {
    deviceId: '61318875-7f7bad72',
    clientName: 'Bilservice',
    coordinates: [10.283544, 59.49614],
    color: '#24618E',
  },
  {
    deviceId: '6131888c-4c7285fd',
    clientName: 'Bilservice',
    coordinates: [10.179686, 59.139604],
    color: '#24618E',
  },
  {
    deviceId: '6131888e-eaf6eca6',
    clientName: 'Autoplan',
    coordinates: [11.2993739, 60.8315544],
    color: '#fbcc06',
  },
  {
    deviceId: '613188bf-588eb0fb',
    clientName: 'Bilservice',
    coordinates: [9.6495856, 59.6445346],
    color: '#24618E',
  },
  {
    deviceId: '61318d64-786fa547',
    clientName: 'Frydenbø',
    coordinates: [5.3228137, 60.4615413],
    color: '#35DCC3',
  },
  {
    deviceId: '61318d6a-7f7bad58',
    clientName: 'Moss To-Takt',
    coordinates: [10.6767994, 59.4159523],
    color: '#141f26',
  },
  {
    deviceId: '61318da6-830fa553',
    clientName: 'Bilservice',
    coordinates: [10.179686, 59.139604],
    color: '#24618E',
  },
  {
    deviceId: '61318e11-830fa53c',
    clientName: 'Bilservice',
    coordinates: [10.179686, 59.139604],
    color: '#24618E',
  },
  {
    deviceId: '6131fbd8-7f7bad79',
    clientName: 'Bilservice',
    coordinates: [10.179686, 59.139604],
    color: '#24618E',
  },
  {
    deviceId: '6131fc54-830fa556',
    clientName: 'Moss To-Takt',
    coordinates: [10.6767994, 59.4159523],
    color: '#141f26',
  },
  {
    deviceId: '6131fc58-830fa518',
    clientName: 'Bilservice',
    coordinates: [10.179686, 59.139604],
    color: '#24618E',
  },
  {
    deviceId: '6132712b-7f7badb0',
    clientName: 'Berge & Co',
    coordinates: [5.8865328, 61.4461781],
    color: '#A60053',
  },
  { deviceId: '6132eac7-a34c3074', clientName: 'Frydenbø', coordinates: [5.230024, 60.380727], color: '#35DCC3' },
  {
    deviceId: '6132eade-77c54c5a',
    clientName: 'Frydenbø',
    coordinates: [5.3427893, 60.3658917],
    color: '#35DCC3',
  },
  {
    deviceId: '6132eae0-28616766',
    clientName: 'Frydenbø',
    coordinates: [5.8865328, 61.4461781],
    color: '#35DCC3',
  },
  { deviceId: '6132eaf0-86b11cd', clientName: 'Roa Auto', coordinates: [10.624454, 60.288248], color: '#000000' },
  {
    deviceId: '6132eafd-d88ead58',
    clientName: 'Okkenhaug',
    coordinates: [9.8349564, 63.2949699],
    color: '#045383',
  },
  {
    deviceId: '6132eb32-7f7bad17',
    clientName: 'Bilservice',
    coordinates: [9.6495856, 59.6445346],
    color: '#24618E',
  },
  {
    deviceId: '613b1960-7f7bacfa',
    clientName: 'Centralgaragen',
    coordinates: [10.1738495, 59.748334],
    color: '#0B1E26',
  },
  {
    deviceId: '613b87c0-7f7bad74',
    clientName: 'Bilservice',
    coordinates: [10.179686, 59.139604],
    color: '#24618E',
  },
  {
    deviceId: '613b87e5-7f7badb2',
    clientName: 'Bilservice',
    coordinates: [10.283544, 59.49614],
    color: '#24618E',
  },
  {
    deviceId: '613b87f3-7f7bacfc',
    clientName: 'Berge & Co',
    coordinates: [5.8865328, 61.4461781],
    color: '#A60053',
  },
  {
    deviceId: '613b881d-7f7bad1a',
    clientName: 'Bilservice',
    coordinates: [9.6495856, 59.6445346],
    color: '#24618E',
  },
  { deviceId: '613b881f-7f7bad59', clientName: 'Mobile', coordinates: [10.790412, 59.915991], color: '#E35205' },
  {
    deviceId: '613b883a-7f7bad95',
    clientName: 'Berge & Co',
    coordinates: [7.1001102, 61.2260773],
    color: '#A60053',
  },
  {
    deviceId: '613b888a-7f7badb4',
    clientName: 'Berge & Co',
    coordinates: [6.7258411, 61.9051518],
    color: '#A60053',
  },
  {
    deviceId: '613b88be-7f7bad92',
    clientName: 'Berge & Co',
    coordinates: [6.135967, 62.197344],
    color: '#A60053',
  },
  {
    deviceId: '613b88eb-7f7bacfb',
    clientName: 'Berge & Co',
    coordinates: [5.8865328, 61.4461781],
    color: '#A60053',
  },
  {
    deviceId: '613b8dd2-7f7bad73',
    clientName: 'Bilservice',
    coordinates: [10.179686, 59.139604],
    color: '#24618E',
  },
  {
    deviceId: '613b8e01-7f7bacfe',
    clientName: 'Bilservice',
    coordinates: [10.283544, 59.49614],
    color: '#24618E',
  },
  {
    deviceId: 'a0cbd9f9-4c468ccb',
    clientName: 'Sven Kvia',
    coordinates: [5.6933918, 58.9043258],
    color: '#404040',
  },
  {
    deviceId: 'a0def1c2-c7604dbf',
    clientName: 'Bilservice',
    coordinates: [10.179686, 59.139604],
    color: '#24618E',
  },
  {
    deviceId: 'a0df6003-cd2c790c',
    clientName: 'Centralgaragen',
    coordinates: [10.1738495, 59.748334],
    color: '#0B1E26',
  },
  {
    deviceId: 'a0dfd54b-eccd795c',
    clientName: 'Bilservice',
    coordinates: [10.179686, 59.139604],
    color: '#24618E',
  },
  {
    deviceId: 'a0dfda7c-86eccd8e',
    clientName: 'Sven Kvia',
    coordinates: [5.6933918, 58.9043258],
    color: '#404040',
  },
  {
    deviceId: 'a0e0489c-59ae0126',
    clientName: 'Sven Kvia',
    coordinates: [5.6933918, 58.9043258],
    color: '#404040',
  },
  {
    deviceId: 'ca053c53-b49b9245',
    clientName: 'Bilservice',
    coordinates: [10.179686, 59.139604],
    color: '#24618E',
  },
  { deviceId: 'ca053c6a-8e116369', clientName: 'Mobile', coordinates: [10.210918, 59.73309], color: '#E35205' },
  { deviceId: 'ca053c8d-5dba996e', clientName: 'Frydenbø', coordinates: [6.229773, 61.768344], color: '#35DCC3' },
  {
    deviceId: 'ca053ca8-195d703f',
    clientName: 'Centralgaragen',
    coordinates: [10.1738495, 59.748334],
    color: '#0B1E26',
  },
  {
    deviceId: 'ca053cba-c0588d4',
    clientName: 'Okkenhaug',
    coordinates: [10.466325, 63.433425],
    color: '#045383',
  },
  {
    deviceId: 'ca053cbc-15272d24',
    clientName: 'Autoplan',
    coordinates: [11.2993739, 60.8315544],
    color: '#fbcc06',
  },
  { deviceId: 'ca053ccf-a26316d1', clientName: 'Mobile', coordinates: [10.663085, 59.918467], color: '#E35205' },
  {
    deviceId: 'ca053cdd-6d862b3d',
    clientName: 'Birger N. Haug Drammen',
    coordinates: [10.2594438, 59.7676325],
    color: '#272A29',
  },
  {
    deviceId: 'ca053ce6-b906a046',
    clientName: 'Frydenbø',
    coordinates: [5.3427893, 60.3658917],
    color: '#35DCC3',
  },
  {
    deviceId: 'ca12e507-d19352f5',
    clientName: 'Berge & Co',
    coordinates: [5.8865328, 61.4461781],
    color: '#A60053',
  },
  {
    deviceId: 'ca12e530-800c92ed',
    clientName: 'Autoplan',
    coordinates: [11.2993739, 60.8315544],
    color: '#fbcc06',
  },
  {
    deviceId: 'ca12e541-fe2b267d',
    clientName: 'Frydenbø',
    coordinates: [5.3427893, 60.3658917],
    color: '#35DCC3',
  },
  {
    deviceId: 'ca1353bf-3cae7c73',
    clientName: 'Frydenbø',
    coordinates: [7.1001102, 61.2260773],
    color: '#35DCC3',
  },
  {
    deviceId: 'ca1353ed-7545f952',
    clientName: 'Bertel O. Steen',
    coordinates: [9.613724, 59.1744616],
    color: '#30306D',
  },
  {
    deviceId: '36dd7401-8b19100f',
    clientName: 'Bertel O. Steen',
    coordinates: [9.613724, 59.1744616],
    color: '#30306D',
  },
  {
    deviceId: '6132eb0f-9d2b072b',
    clientName: 'Bertel O. Steen',
    coordinates: [10.4301301, 59.2775458],
    color: '#30306D',
  },
  {
    deviceId: 'c9f6b603-8449d6fc',
    clientName: 'Bertel O. Steen',
    coordinates: [10.062037417178253, 59.04488033437097],
    color: '#30306D',
  },
  {
    deviceId: '6119700c-8b190d2b',
    clientName: 'Bertel O. Steen',
    coordinates: [11.050965440618764, 60.84000209641986],
    color: '#30306D',
  },
  {
    deviceId: '613bfd1d-8b190d48',
    clientName: 'Bertel O. Steen',
    coordinates: [11.050965440618764, 60.84000209641986],
    color: '#30306D',
  },
  {
    deviceId: '83d76e8a-8b190d07',
    clientName: 'Bertel O. Steen',
    coordinates: [11.050965440618764, 60.84000209641986],
    color: '#30306D',
  },
  {
    deviceId: '83d76f23-8b190d06',
    clientName: 'Bertel O. Steen',
    coordinates: [11.050965440618764, 60.84000209641986],
    color: '#30306D',
  },
  { deviceId: 'ca13543b-b86b56a', clientName: 'Frydenbø', coordinates: [5.3427893, 60.3658917], color: '#35DCC3' },
  {
    deviceId: 'ca13543f-3ac0bfea',
    clientName: 'Frydenbø',
    coordinates: [5.3427893, 60.3658917],
    color: '#35DCC3',
  },
  {
    deviceId: 'ca13544c-ee617e9e',
    clientName: 'Roa Auto',
    coordinates: [10.2495952, 60.154752],
    color: '#000000',
  },
  {
    deviceId: 'ca13547d-195d70b8',
    clientName: 'Frydenbø',
    coordinates: [5.3228137, 60.4615413],
    color: '#35DCC3',
  },
  {
    deviceId: 'ca216af0-243b508f',
    clientName: 'Autoplan',
    coordinates: [11.2993739, 60.8315544],
    color: '#fbcc06',
  },
  {
    deviceId: 'ca216af2-78993e90',
    clientName: 'Centralgaragen',
    coordinates: [10.1738495, 59.748334],
    color: '#0B1E26',
  },
  { deviceId: 'ca216b70-5996ae7c', clientName: 'Frydenbø', coordinates: [8.1289714, 58.174678], color: '#35DCC3' },
  {
    deviceId: 'ca216b9d-3c22de23',
    clientName: 'Frydenbø',
    coordinates: [7.4664959, 58.0203576],
    color: '#35DCC3',
  },
  { deviceId: 'ca216bcd-36b075ef', clientName: 'Mobile', coordinates: [10.790412, 59.915991], color: '#E35205' },
  {
    deviceId: 'ca216bdd-68d0d881',
    clientName: 'Frydenbø',
    coordinates: [5.3427893, 60.3658917],
    color: '#35DCC3',
  },
  {
    deviceId: 'ca216be8-446bd733',
    clientName: 'RSA Bil',
    coordinates: [10.784630003701468, 59.91510412917446],
    color: '#2020B1',
  },
  {
    deviceId: 'ca217110-ffe7c1b0',
    clientName: 'Frydenbø',
    coordinates: [5.3427893, 60.3658917],
    color: '#35DCC3',
  },
  {
    deviceId: 'ca2f14ae-13985b34',
    clientName: 'Frydenbø',
    coordinates: [5.3427893, 60.3658917],
    color: '#35DCC3',
  },
  {
    deviceId: 'e3c7630d-5846d41e',
    clientName: 'Centralgaragen',
    coordinates: [10.1738495, 59.748334],
    color: '#0B1E26',
  },
  {
    deviceId: 'e6738512-5846fedb',
    clientName: 'Centralgaragen',
    coordinates: [10.1738495, 59.748334],
    color: '#0B1E26',
  },
  {
    deviceId: 'e73478f5-a521e985',
    clientName: 'Moss To-Takt',
    coordinates: [10.6767994, 59.4159523],
    color: '#141f26',
  },
  {
    deviceId: 'e746d2b-7f7bad99',
    clientName: 'Bilservice',
    coordinates: [9.6495856, 59.6445346],
    color: '#24618E',
  },
  {
    deviceId: 'e746d4c-2903b3de',
    clientName: "Branko's Auto",
    coordinates: [10.4195113, 59.2636152],
    color: '#333333',
  },
  { deviceId: 'eb32880-7f7bad96', clientName: 'Berge & Co', coordinates: [6.135967, 62.197344], color: '#A60053' },
  {
    deviceId: 'eb328ce-7f7bad94',
    clientName: 'Berge & Co',
    coordinates: [5.8865328, 61.4461781],
    color: '#A60053',
  },
  {
    deviceId: 'peterTester',
    clientName: 'Short Shift',
    coordinates: [10.746797, 59.910774],
    color: '#165FFB',
  },
  {
    deviceId: 'c9f6b129-dc289a07',
    clientName: 'Auto 8-8 Årø (Hyundai)',
    coordinates: [7.2487871, 62.7453955],
    color: '#1A2C5D',
  },
  {
    deviceId: 'c9f6b61c-40857627',
    clientName: 'Auto 8-8 Forum (Hyundai)',
    coordinates: [5.8820814, 61.4473341],
    color: '#1A2C5D',
  },
  {
    deviceId: 'f85d8195-33006b31',
    clientName: 'Sven Kvia (Hyundai)',
    coordinates: [5.6933918, 58.9043258],
    color: '#1A2C5D',
  },
  {
    deviceId: 'ca216af8-2031940b',
    clientName: 'Gjermundsen Auto Larvik (Hyundai)',
    coordinates: [10.065411, 59.086775],
    color: '#1A2C5D',
  },
  {
    deviceId: 'ca053cd9-b5769b40',
    clientName: 'Biltrend Harstad (Hyundai)',
    coordinates: [16.5918219, 68.7787432],
    color: '#1A2C5D',
  },
  {
    deviceId: 'ca2170e3-9add9e44',
    clientName: 'Mobile Kokstad (Hyundai)',
    coordinates: [5.26435839572747, 60.2967088848431],
    color: '#1A2C5D',
  },
  {
    deviceId: 'e66caed6-af8f0cb9',
    clientName: 'Steinkjer Bil (Hyundai)',
    coordinates: [11.488844, 64.009143],
    color: '#1A2C5D',
  },
  {
    deviceId: 'ca216bfe-fc0a234c',
    clientName: 'Gjermundsen Bilsenter Sandefjord (Hyundai)',
    coordinates: [10.252349, 59.133875],
    color: '#1A2C5D',
  },
  {
    deviceId: '2acb902a-cdf443dd',
    clientName: 'Centralgaragen (Hyundai)',
    coordinates: [10.1738495, 59.748334],
    color: '#1A2C5D',
  },
  {
    deviceId: 'ca13543d-ad97ddb7',
    clientName: 'Birger N. Haug Oslo (Hyundai)',
    coordinates: [10.824481, 59.930856],
    color: '#1A2C5D',
  },
  {
    deviceId: 'ca12e5ae-70760889',
    clientName: 'Nymo Bil (Hyundai)',
    coordinates: [14.4099005934601, 67.2765200626252],
    color: '#1A2C5D',
  },
  {
    deviceId: 'ca12e5cb-1c1b32f8',
    clientName: 'Mobile Skien (Hyundai)',
    coordinates: [9.6167319, 59.1714836],
    color: '#1A2C5D',
  },
  {
    deviceId: 'ca135396-5922ea63',
    clientName: 'Mobile Rødberg (Hyundai)',
    coordinates: [8.9355194, 60.2651242],
    color: '#1A2C5D',
  },
  {
    deviceId: 'ca135392-82821250',
    clientName: 'Biltrend Vesterålen (Hyundai)',
    coordinates: [15.3974606, 68.6811975],
    color: '#1A2C5D',
  },
  {
    deviceId: 'ca216b2e-67841019',
    clientName: 'Auto 8-8 Forum (Hyundai)',
    coordinates: [5.8820814, 61.4473341],
    color: '#1A2C5D',
  },
  {
    deviceId: 'ca053c8b-b798f8f8',
    clientName: 'Strandveien Auto Verdal (Hyundai)',
    coordinates: [11.455636, 63.7681687],
    color: '#1A2C5D',
  },
  {
    deviceId: 'ca13545c-cce6b6a6',
    clientName: 'Gol Auto AS (Hyundai)',
    coordinates: [8.9303139, 60.6957558],
    color: '#1A2C5D',
  },
  {
    deviceId: 'e64ead6d-b127b67a',
    clientName: 'Auto 8-8 Sildnes og Halaas (Hyundai)',
    coordinates: [7.8000962, 63.1113757],
    color: '#1A2C5D',
  },
  {
    deviceId: 'ca12e57f-f9d9b424',
    clientName: 'Biltrend Harstad (Hyundai)',
    coordinates: [16.5918219, 68.7787432],
    color: '#1A2C5D',
  },
  {
    deviceId: '613b8804-7f7bacff',
    clientName: 'Sulland Drammen (Hyundai)',
    coordinates: [10.1738495, 59.748334],
    color: '#1A2C5D',
  },
  {
    deviceId: 'ca217133-a165689a',
    clientName: 'Strandveien Auto Verdal (Hyundai)',
    coordinates: [11.455636, 63.7681687],
    color: '#1A2C5D',
  },
  {
    deviceId: 'ca1353c3-762aa2e2',
    clientName: 'Nymo Bil (Hyundai)',
    coordinates: [14.4099005934601, 67.2765200626252],
    color: '#1A2C5D',
  },
  {
    deviceId: 'ca216b8b-51b75b47',
    clientName: 'Gjermundsen Auto Larvik (Hyundai)',
    coordinates: [10.065411, 59.086775],
    color: '#1A2C5D',
  },
  {
    deviceId: 'ca216b74-9eb243de',
    clientName: 'Mobile Skien (Hyundai)',
    coordinates: [9.6167319, 59.1714836],
    color: '#1A2C5D',
  },
  {
    deviceId: 'ca135498-62422670',
    clientName: 'Gol Auto AS (Hyundai)',
    coordinates: [8.9303139, 60.6957558],
    color: '#1A2C5D',
  },
  {
    deviceId: 'ca12e59a-765985d',
    clientName: 'Alleen Auto (Hyundai)',
    coordinates: [7.06201075830532, 58.1408201921519],
    color: '#1A2C5D',
  },
  {
    deviceId: '22e84f7f-af8f0cbc',
    clientName: 'Steinkjer Bil (Hyundai)',
    coordinates: [11.488844, 64.009143],
    color: '#1A2C5D',
  },
  {
    deviceId: 'e3ffbb26-5846d792',
    clientName: 'Sulland Drammen (Hyundai)',
    coordinates: [10.1738495, 59.748334],
    color: '#1A2C5D',
  },
  {
    deviceId: 'ca12e524-fc097bfd',
    clientName: 'Autohuset Alta (Hyundai)',
    coordinates: [23.2680547, 69.9678519],
    color: '#1A2C5D',
  },
  {
    deviceId: 'e643c4c9-af8f0cda',
    clientName: 'Bauge Auto (Hyundai)',
    coordinates: [5.3072027, 59.3859118],
    color: '#1A2C5D',
  },
  {
    deviceId: '2acb9026-fa6df953',
    clientName: 'Moss To-Takt (Hyundai)',
    coordinates: [10.6767994, 59.4159523],
    color: '#1A2C5D',
  },
  {
    deviceId: 'ca12e57d-ce54ebb5',
    clientName: 'Mobile Sørlandsparken (Hyundai)',
    coordinates: [8.125766, 58.1734413],
    color: '#1A2C5D',
  },
  {
    deviceId: 'ca216c09-e4d2be45',
    clientName: 'Birger N. Haug Vøyenenga (Hyundai)',
    coordinates: [10.4645511, 59.917553],
    color: '#1A2C5D',
  },
  {
    deviceId: 'e66e0aeb-b127b65a',
    clientName: 'Auto 8-8 Rico (Hyundai)',
    coordinates: [6.3813716, 62.4709433],
    color: '#1A2C5D',
  },
  {
    deviceId: 'ca12e583-cc526bba',
    clientName: 'Auto 8-8 Forum (Hyundai)',
    coordinates: [5.8820814, 61.4473341],
    color: '#1A2C5D',
  },
  {
    deviceId: 'ca053c9f-1ff1ab21',
    clientName: 'Sulland Elverum (Hyundai)',
    coordinates: [11.5304889, 60.8881761],
    color: '#1A2C5D',
  },
  {
    deviceId: 'ca12e503-b853ce82',
    clientName: 'Autohuset Alta (Hyundai)',
    coordinates: [23.2680547, 69.9678519],
    color: '#1A2C5D',
  },
  {
    deviceId: 'ca216b13-dd3815eb',
    clientName: 'Biltrend Harstad (Hyundai)',
    coordinates: [16.5918219, 68.7787432],
    color: '#1A2C5D',
  },
  {
    deviceId: 'ca1353a2-974d27f4',
    clientName: 'Birger N. Haug Vøyenenga (Hyundai)',
    coordinates: [10.4645511, 59.917553],
    color: '#1A2C5D',
  },
  {
    deviceId: 'ca053c9b-2010cf28',
    clientName: 'Strandveien Auto Stjørdal (Hyundai)',
    coordinates: [10.8962088656303, 63.4765632688906],
    color: '#1A2C5D',
  },
  {
    deviceId: 'ca135431-15931b4f',
    clientName: 'Mobile Rødberg (Hyundai)',
    coordinates: [8.9355194, 60.2651242],
    color: '#1A2C5D',
  },
  {
    deviceId: 'a0def200-bf60eae',
    clientName: 'Auto 8-8 Forum (Hyundai)',
    coordinates: [5.8820814, 61.4473341],
    color: '#1A2C5D',
  },
  {
    deviceId: 'ca217102-bf1ad67b',
    clientName: 'Sulland Kongsvinger (Hyundai)',
    coordinates: [11.9643662, 60.2090679],
    color: '#1A2C5D',
  },
  {
    deviceId: 'ca216bbc-621c6189',
    clientName: 'Sulland Elverum (Hyundai)',
    coordinates: [11.5304889, 60.8881761],
    color: '#1A2C5D',
  },
  {
    deviceId: 'ca21712f-b0a3b411',
    clientName: 'Biltrend Harstad (Hyundai)',
    coordinates: [16.5918219, 68.7787432],
    color: '#1A2C5D',
  },
  {
    deviceId: 'ca216c38-6173361e',
    clientName: 'Sulland Lillehammer (Hyundai)',
    coordinates: [10.4458382, 61.1284909],
    color: '#1A2C5D',
  },
  {
    deviceId: 'ca13549a-1a1664ba',
    clientName: 'Biltrend Tromsø (Hyundai)',
    coordinates: [18.95026, 69.628289],
    color: '#1A2C5D',
  },
  {
    deviceId: 'c9f6b624-ad0ff178',
    clientName: 'Strandveien Auto Stjørdal (Hyundai)',
    coordinates: [10.8962088656303, 63.4765632688906],
    color: '#1A2C5D',
  },
  {
    deviceId: 'ca216bea-3bd4e097',
    clientName: 'Birger N. Haug Drammen (Hyundai)',
    coordinates: [10.2594438, 59.7676325],
    color: '#1A2C5D',
  },
  {
    deviceId: 'a0dfd53b-9c8d7083',
    clientName: 'Biltrend Harstad (Hyundai)',
    coordinates: [16.5918219, 68.7787432],
    color: '#1A2C5D',
  },
  {
    deviceId: 'ca216b6e-9e6b1110',
    clientName: 'Strandveien Auto Stjørdal (Hyundai)',
    coordinates: [10.8962088656303, 63.4765632688906],
    color: '#1A2C5D',
  },
  {
    deviceId: 'e64eae1c-b127b696',
    clientName: 'Brennes Auto (Hyundai)',
    coordinates: [11.0874975, 59.2762372],
    color: '#1A2C5D',
  },
  {
    deviceId: 'e643c478-b127b653',
    clientName: 'Brennes Auto (Hyundai)',
    coordinates: [11.0874975, 59.2762372],
    color: '#1A2C5D',
  },
  {
    deviceId: 'c9f6b601-74ecd745',
    clientName: 'Birger N. Haug Vøyenenga (Hyundai)',
    coordinates: [10.4645511, 59.917553],
    color: '#1A2C5D',
  },
  {
    deviceId: '2c2f92cf-33006927',
    clientName: 'Gjermundsen Auto Tønsberg (Hyundai)',
    coordinates: [10.435934, 59.275179],
    color: '#1A2C5D',
  },
  {
    deviceId: 'ca1353e0-b2fceea3',
    clientName: 'Nymo Bil (Hyundai)',
    coordinates: [14.4099005934601, 67.2765200626252],
    color: '#1A2C5D',
  },
  {
    deviceId: 'ca053c5f-893abd11',
    clientName: 'Birger N. Haug Drammen (Hyundai)',
    coordinates: [10.2594438, 59.7676325],
    color: '#1A2C5D',
  },
  {
    deviceId: 'ca216c0d-167336d2',
    clientName: 'Gjermundsen Bilsenter Sandefjord (Hyundai)',
    coordinates: [10.252349, 59.133875],
    color: '#1A2C5D',
  },
  {
    deviceId: 'ca13536f-772b1fc7',
    clientName: 'Glommen Bil (Hyundai)',
    coordinates: [11.5749645, 60.1962002],
    color: '#1A2C5D',
  },
  {
    deviceId: 'e66cb3cb-5846fedf',
    clientName: 'Brennes Auto (Hyundai)',
    coordinates: [11.0874975, 59.2762372],
    color: '#1A2C5D',
  },
  {
    deviceId: 'e673f37c-5846fed2',
    clientName: 'Auto 8-8 Rico (Hyundai)',
    coordinates: [6.3813716, 62.4709433],
    color: '#1A2C5D',
  },
  {
    deviceId: 'ca053c9d-c3b2fd97',
    clientName: 'Okkenhaug (Hyundai)',
    coordinates: [10.466325, 63.433425],
    color: '#1A2C5D',
  },
  {
    deviceId: 'ca12e59c-5177366f',
    clientName: 'Fylkesnes Bil (Hyundai)',
    coordinates: [5.4549046, 59.7615241],
    color: '#1A2C5D',
  },
  {
    deviceId: 'ca217121-37353168',
    clientName: 'Nymo Bil (Hyundai)',
    coordinates: [14.4099005934601, 67.2765200626252],
    color: '#1A2C5D',
  },
  {
    deviceId: 'e67385bd-af8f0cbd',
    clientName: 'Lieco Auto (Hyundai)',
    coordinates: [5.1298748, 60.3519986],
    color: '#1A2C5D',
  },
  {
    deviceId: 'ca216c3a-2284a327',
    clientName: 'Auto 8-8 Årø (Hyundai)',
    coordinates: [7.2487871, 62.7453955],
    color: '#1A2C5D',
  },
  {
    deviceId: 'f8c61460-c3cddb2c',
    clientName: 'Mobile Ensjø (Hyundai)',
    coordinates: [10.790412, 59.915991],
    color: '#1A2C5D',
  },
  {
    deviceId: 'f85d8161-c3cddb31',
    clientName: 'Mobile Åsane (Hyundai)',
    coordinates: [5.3424375, 60.4694341],
    color: '#1A2C5D',
  },
  {
    deviceId: 'ca21711f-c3b4ce37',
    clientName: 'Alleen Auto (Hyundai)',
    coordinates: [7.06201075830532, 58.1408201921519],
    color: '#1A2C5D',
  },
  {
    deviceId: 'ca216bac-44c5a412',
    clientName: 'Sulland Midtstranda (Hyundai)',
    coordinates: [11.1148041, 60.797667],
    color: '#1A2C5D',
  },
  {
    deviceId: 'e66cb3cf-af8f0cbb',
    clientName: 'Lieco Auto (Hyundai)',
    coordinates: [5.1298748, 60.3519986],
    color: '#1A2C5D',
  },
  {
    deviceId: 'ca13547b-b21e6ebc',
    clientName: 'Sulland Lillehammer (Hyundai)',
    coordinates: [10.4458382, 61.1284909],
    color: '#1A2C5D',
  },
  {
    deviceId: '2acbfe0e-217aee40',
    clientName: 'Mobile Ensjø (Hyundai)',
    coordinates: [10.790412, 59.915991],
    color: '#1A2C5D',
  },
  {
    deviceId: 'ca053c89-87343f27',
    clientName: 'Gjermundsen Bilsenter Sandefjord (Hyundai)',
    coordinates: [10.252349, 59.133875],
    color: '#1A2C5D',
  },
  {
    deviceId: '22d0abca-af8f0cdc',
    clientName: 'Okkenhaug (Hyundai)',
    coordinates: [10.466325, 63.433425],
    color: '#1A2C5D',
  },
  {
    deviceId: 'f8be5a7c-c3cddb29',
    clientName: 'Mobile Sørlandsparken (Hyundai)',
    coordinates: [8.125766, 58.1734413],
    color: '#1A2C5D',
  },
  {
    deviceId: 'ca217131-3143af94',
    clientName: 'Biltrend Tromsø (Hyundai)',
    coordinates: [18.95026, 69.628289],
    color: '#1A2C5D',
  },
  {
    deviceId: 'e673801b-5846fd7b',
    clientName: 'Okkenhaug (Hyundai)',
    coordinates: [10.466325, 63.433425],
    color: '#1A2C5D',
  },
  {
    deviceId: '613b87a7-7f7bacf9',
    clientName: 'Sulland Drammen (Hyundai)',
    coordinates: [10.1738495, 59.748334],
    color: '#1A2C5D',
  },
  {
    deviceId: 'ca216b53-4379ab72',
    clientName: 'Sulland Elverum (Hyundai)',
    coordinates: [11.5304889, 60.8881761],
    color: '#1A2C5D',
  },
  {
    deviceId: 'ca1353ad-c10c488b',
    clientName: 'Birger N. Haug Oslo (Hyundai)',
    coordinates: [10.824481, 59.930856],
    color: '#1A2C5D',
  },
  {
    deviceId: 'ca216b82-a14bd9ef',
    clientName: 'Nymo Bil (Hyundai)',
    coordinates: [14.4099005934601, 67.2765200626252],
    color: '#1A2C5D',
  },
  {
    deviceId: 'ca1353cc-d66b1aa9',
    clientName: 'Sulland Elverum (Hyundai)',
    coordinates: [11.5304889, 60.8881761],
    color: '#1A2C5D',
  },
  {
    deviceId: 'e68e57a0-b127b657',
    clientName: 'Auto 8-8 Sildnes og Halaas (Hyundai)',
    coordinates: [7.8000962, 63.1113757],
    color: '#1A2C5D',
  },
  {
    deviceId: 'ca20fcef-f2049c75',
    clientName: 'Biltrend Tromsø (Hyundai)',
    coordinates: [18.95026, 69.628289],
    color: '#1A2C5D',
  },
  {
    deviceId: 'ca053ccd-cfc6cb91',
    clientName: 'Alleen Auto (Hyundai)',
    coordinates: [7.06201075830532, 58.1408201921519],
    color: '#1A2C5D',
  },
  {
    deviceId: 'ca20fcdf-66db6c53',
    clientName: 'Mobile Rødberg (Hyundai)',
    coordinates: [8.9355194, 60.2651242],
    color: '#1A2C5D',
  },
  {
    deviceId: 'ca216bec-ba31cec2',
    clientName: 'Sulland Lillehammer (Hyundai)',
    coordinates: [10.4458382, 61.1284909],
    color: '#1A2C5D',
  },
  {
    deviceId: 'ca135373-ca8fd802',
    clientName: 'Gjermundsen Bilsenter Sandefjord (Hyundai)',
    coordinates: [10.252349, 59.133875],
    color: '#1A2C5D',
  },
  {
    deviceId: 'ca20fcc6-25181f44',
    clientName: 'Biltrend Vesterålen (Hyundai)',
    coordinates: [15.3974606, 68.6811975],
    color: '#1A2C5D',
  },
  {
    deviceId: 'ca216b25-6d412e80',
    clientName: 'Fylkesnes Bil (Hyundai)',
    coordinates: [5.4549046, 59.7615241],
    color: '#1A2C5D',
  },
  {
    deviceId: '22d03d39-b127b675',
    clientName: 'Auto 8-8 Rico (Hyundai)',
    coordinates: [6.3813716, 62.4709433],
    color: '#1A2C5D',
  },
  {
    deviceId: 'ca053cc9-ba52a530',
    clientName: 'Sulland Midtstranda (Hyundai)',
    coordinates: [11.1148041, 60.797667],
    color: '#1A2C5D',
  },
  {
    deviceId: 'f4bb35d3-949f3f57',
    clientName: 'Sven Kvia (Hyundai)',
    coordinates: [5.6933918, 58.9043258],
    color: '#1A2C5D',
  },
  {
    deviceId: 'bbe83d11-ab5eca65',
    clientName: 'Mobile Åsane (Hyundai)',
    coordinates: [5.3424375, 60.4694341],
    color: '#1A2C5D',
  },
  {
    deviceId: '2acb8af1-ea2186c0',
    clientName: 'Moss To-Takt (Hyundai)',
    coordinates: [10.6767994, 59.4159523],
    color: '#1A2C5D',
  },
  {
    deviceId: 'a0e137fa-6179abec',
    clientName: 'Birger N. Haug Gardermoen (Hyundai)',
    coordinates: [11.1529411988385, 60.1673914807139],
    color: '#1A2C5D',
  },
  {
    deviceId: 'ca216b5f-2f94e7da',
    clientName: 'Sulland Lillehammer (Hyundai)',
    coordinates: [10.4458382, 61.1284909],
    color: '#1A2C5D',
  },
  {
    deviceId: 'f87853c4-b5ed8a2d',
    clientName: 'Mobile Ensjø (Hyundai)',
    coordinates: [10.790412, 59.915991],
    color: '#1A2C5D',
  },
  {
    deviceId: 'ca135486-5be6ad20',
    clientName: 'Mobile Kokstad (Hyundai)',
    coordinates: [5.26435839572747, 60.2967088848431],
    color: '#1A2C5D',
  },
  {
    deviceId: 'ca12e570-969fae51',
    clientName: 'Birger N. Haug Vøyenenga (Hyundai)',
    coordinates: [10.4645511, 59.917553],
    color: '#1A2C5D',
  },
  {
    deviceId: 'bfb3cc03-949f3f53',
    clientName: 'Bilalliansen (Hyundai)',
    coordinates: [14.1556144, 66.3107117],
    color: '#1A2C5D',
  },
  {
    deviceId: 'ca2f1485-ad7cbbdd',
    clientName: 'Mobile Kokstad (Hyundai)',
    coordinates: [5.26435839572747, 60.2967088848431],
    color: '#1A2C5D',
  },
  {
    deviceId: 'ca216b6c-f94d55b8',
    clientName: 'Strandveien Auto Stjørdal (Hyundai)',
    coordinates: [10.8962088656303, 63.4765632688906],
    color: '#1A2C5D',
  },
  {
    deviceId: 'f751121b-949f3f6f',
    clientName: 'Sven Kvia (Hyundai)',
    coordinates: [5.6933918, 58.9043258],
    color: '#1A2C5D',
  },
  {
    deviceId: 'ca216b40-68a7074d',
    clientName: 'Autohuset Alta (Hyundai)',
    coordinates: [23.2680547, 69.9678519],
    color: '#1A2C5D',
  },
  {
    deviceId: 'ca2170e5-acc89d70',
    clientName: 'Birger N. Haug Drammen (Hyundai)',
    coordinates: [10.2594438, 59.7676325],
    color: '#1A2C5D',
  },
  {
    deviceId: 'e3dda2d1-af8f0cd8',
    clientName: 'Steinkjer Bil (Hyundai)',
    coordinates: [11.488844, 64.009143],
    color: '#1A2C5D',
  },
  {
    deviceId: 'ca053ce8-e7da8526',
    clientName: 'Moss To-Takt (Hyundai)',
    coordinates: [10.6767994, 59.4159523],
    color: '#1A2C5D',
  },
  {
    deviceId: 'ca216bfa-a074c899',
    clientName: 'Auto 8-8 Forum (Hyundai)',
    coordinates: [5.8820814, 61.4473341],
    color: '#1A2C5D',
  },
  {
    deviceId: 'c9f6b630-aeaddf7',
    clientName: 'Birger N. Haug Oslo (Hyundai)',
    coordinates: [10.824481, 59.930856],
    color: '#1A2C5D',
  },
  {
    deviceId: 'ca135448-91ec5890',
    clientName: 'Gol Auto AS (Hyundai)',
    coordinates: [8.9303139, 60.6957558],
    color: '#1A2C5D',
  },
  {
    deviceId: '2acb8a96-594da831',
    clientName: 'Moss To-Takt (Hyundai)',
    coordinates: [10.6767994, 59.4159523],
    color: '#1A2C5D',
  },
  {
    deviceId: 'f8a970bb-b5ed8a30',
    clientName: 'Mobile Åsane (Hyundai)',
    coordinates: [5.3424375, 60.4694341],
    color: '#1A2C5D',
  },
  {
    deviceId: '2acb8ad6-b628e8b',
    clientName: 'Moss To-Takt (Hyundai)',
    coordinates: [10.6767994, 59.4159523],
    color: '#1A2C5D',
  },
  {
    deviceId: 'f75117c3-949f3f6e',
    clientName: 'Centralgaragen (Hyundai)',
    coordinates: [10.1738495, 59.748334],
    color: '#1A2C5D',
  },
  {
    deviceId: 'ca216b8f-1494e0a5',
    clientName: 'Alleen Auto (Hyundai)',
    coordinates: [7.06201075830532, 58.1408201921519],
    color: '#1A2C5D',
  },
  {
    deviceId: 'ca12e57b-bd829449',
    clientName: 'Autohuset Alta (Hyundai)',
    coordinates: [23.2680547, 69.9678519],
    color: '#1A2C5D',
  },
  {
    deviceId: 'ca217100-85124630',
    clientName: 'Strandveien Auto Verdal (Hyundai)',
    coordinates: [11.455636, 63.7681687],
    color: '#1A2C5D',
  },
  {
    deviceId: 'ca053cb0-ac895a58',
    clientName: 'Sulland Elverum (Hyundai)',
    coordinates: [11.5304889, 60.8881761],
    color: '#1A2C5D',
  },
  {
    deviceId: 'ca20fcf1-70037cc4',
    clientName: 'Roa Auto (Hyundai)',
    coordinates: [10.624454, 60.288248],
    color: '#1A2C5D',
  },
  {
    deviceId: 'f4d764d1-949f3f50',
    clientName: 'Sven Kvia (Hyundai)',
    coordinates: [5.6933918, 58.9043258],
    color: '#1A2C5D',
  },
  {
    deviceId: 'ca12e520-e84297ac',
    clientName: 'Roa Auto (Hyundai)',
    coordinates: [10.624454, 60.288248],
    color: '#1A2C5D',
  },
  {
    deviceId: 'ca216bb0-3930888b',
    clientName: 'Mobile Skien (Hyundai)',
    coordinates: [9.6167319, 59.1714836],
    color: '#1A2C5D',
  },
  {
    deviceId: 'e6780b23-af8f0cdd',
    clientName: 'Bauge Auto (Hyundai)',
    coordinates: [5.3072027, 59.3859118],
    color: '#1A2C5D',
  },
  {
    deviceId: 'ca053c4f-1a77d33f',
    clientName: 'Auto 8-8 Sildnes og Halaas (Hyundai)',
    coordinates: [7.8000962, 63.1113757],
    color: '#1A2C5D',
  },
  {
    deviceId: 'e64eb360-5846fd7d',
    clientName: 'Fagerthun Bil (Hyundai)',
    coordinates: [17.3685227, 68.4226325],
    color: '#1A2C5D',
  },
  {
    deviceId: 'ca053cc7-12acb55c',
    clientName: 'Biltrend Harstad (Hyundai)',
    coordinates: [16.5918219, 68.7787432],
    color: '#1A2C5D',
  },
  {
    deviceId: 'ca216c0b-73e4fa',
    clientName: 'Sulland Lillehammer (Hyundai)',
    coordinates: [10.4458382, 61.1284909],
    color: '#1A2C5D',
  },
  {
    deviceId: 'e63fad5f-b127b658',
    clientName: 'Auto 8-8 Sildnes og Halaas (Hyundai)',
    coordinates: [7.8000962, 63.1113757],
    color: '#1A2C5D',
  },
  {
    deviceId: 'ca216b23-12eea9f2',
    clientName: 'Birger N. Haug Drammen (Hyundai)',
    coordinates: [10.2594438, 59.7676325],
    color: '#1A2C5D',
  },
  {
    deviceId: 'ca13544e-33af3ee0',
    clientName: 'Biltrend Vesterålen (Hyundai)',
    coordinates: [15.3974606, 68.6811975],
    color: '#1A2C5D',
  },
  {
    deviceId: 'ca216bdf-e5f00e57',
    clientName: 'Sulland Midtstranda (Hyundai)',
    coordinates: [11.1148041, 60.797667],
    color: '#1A2C5D',
  },
  {
    deviceId: 'e682857c-5846fd7c',
    clientName: 'Steinkjer Bil (Hyundai)',
    coordinates: [11.488844, 64.009143],
    color: '#1A2C5D',
  },
  {
    deviceId: 'ca12e532-8cf7b61a',
    clientName: 'Birger N. Haug Vøyenenga (Hyundai)',
    coordinates: [10.4645511, 59.917553],
    color: '#1A2C5D',
  },
  {
    deviceId: 'ca216c3c-64ef71a4',
    clientName: 'Auto 8-8 Årø (Hyundai)',
    coordinates: [7.2487871, 62.7453955],
    color: '#1A2C5D',
  },
  {
    deviceId: 'ca216bbe-5f939c5',
    clientName: 'Gol Auto AS (Hyundai)',
    coordinates: [8.9303139, 60.6957558],
    color: '#1A2C5D',
  },
  {
    deviceId: 'ca053caa-a78ff29',
    clientName: 'Birger N. Haug Gardermoen (Hyundai)',
    coordinates: [11.1529411988385, 60.1673914807139],
    color: '#1A2C5D',
  },
  {
    deviceId: 'f74c117f-949f3f58',
    clientName: 'Sven Kvia (Hyundai)',
    coordinates: [5.6933918, 58.9043258],
    color: '#1A2C5D',
  },
  {
    deviceId: 'ca216b93-6c611e67',
    clientName: 'Sulland Kongsvinger (Hyundai)',
    coordinates: [11.9643662, 60.2090679],
    color: '#1A2C5D',
  },
  {
    deviceId: 'ca053c70-6bbb2c4a',
    clientName: 'Birger N. Haug Vøyenenga (Hyundai)',
    coordinates: [10.4645511, 59.917553],
    color: '#1A2C5D',
  },
  {
    deviceId: 'ca216bcf-440a74d4',
    clientName: 'Okkenhaug (Hyundai)',
    coordinates: [10.466325, 63.433425],
    color: '#1A2C5D',
  },
  {
    deviceId: 'ca216c19-538afb91',
    clientName: 'Gjermundsen Auto Larvik (Hyundai)',
    coordinates: [10.065411, 59.086775],
    color: '#1A2C5D',
  },
  {
    deviceId: 'f8c682bd-c3cddb30',
    clientName: 'Strandveien Auto Stjørdal (Hyundai)',
    coordinates: [10.8962088656303, 63.4765632688906],
    color: '#1A2C5D',
  },
  {
    deviceId: 'e66caed4-af8f0cd6',
    clientName: 'Okkenhaug (Hyundai)',
    coordinates: [10.466325, 63.433425],
    color: '#1A2C5D',
  },
  {
    deviceId: 'ca20fd1d-920d6d2f',
    clientName: 'Gjermundsen Bilsenter Sandefjord (Hyundai)',
    coordinates: [10.252349, 59.133875],
    color: '#1A2C5D',
  },
  {
    deviceId: 'f89bc899-c3cddb2e',
    clientName: 'Mobile Ensjø (Hyundai)',
    coordinates: [10.790412, 59.915991],
    color: '#1A2C5D',
  },
  {
    deviceId: 'ca216b34-6dd1216a',
    clientName: 'Sulland Midtstranda (Hyundai)',
    coordinates: [11.1148041, 60.797667],
    color: '#1A2C5D',
  },
  {
    deviceId: '775a9d3-5846b95d',
    clientName: 'Gjermundsen Auto Tønsberg (Hyundai)',
    coordinates: [10.435934, 59.275179],
    color: '#1A2C5D',
  },
  {
    deviceId: '5e413c7d-c3cddb2b',
    clientName: 'Strandveien Auto Stjørdal (Hyundai)',
    coordinates: [10.8962088656303, 63.4765632688906],
    color: '#1A2C5D',
  },
  {
    deviceId: 'c9f6b620-e7ce5883',
    clientName: 'Alleen Auto (Hyundai)',
    coordinates: [7.06201075830532, 58.1408201921519],
    color: '#1A2C5D',
  },
  {
    deviceId: '339d06f0-339d06f0',
    clientName: 'Bauge Auto (Hyundai)',
    coordinates: [5.3072027, 59.3859118],
    color: '#1A2C5D',
  },
  {
    deviceId: 'ca13544a-95a6f038',
    clientName: 'Gjermundsen Auto Larvik (Hyundai)',
    coordinates: [10.065411, 59.086775],
    color: '#1A2C5D',
  },
  {
    deviceId: '6132eb13-36cb29bf',
    clientName: 'Autohuset Alta (Hyundai)',
    coordinates: [23.2680547, 69.9678519],
    color: '#1A2C5D',
  },
  {
    deviceId: 'e67bb3dd-5846fd86',
    clientName: 'Lieco Auto (Hyundai)',
    coordinates: [5.1298748, 60.3519986],
    color: '#1A2C5D',
  },
  {
    deviceId: 'ca2f14cf-d6d24e46',
    clientName: 'Birger N. Haug Drammen (Hyundai)',
    coordinates: [10.2594438, 59.7676325],
    color: '#1A2C5D',
  },
  {
    deviceId: 'ca216b17-82222f02',
    clientName: 'Alleen Auto (Hyundai)',
    coordinates: [7.06201075830532, 58.1408201921519],
    color: '#1A2C5D',
  },
  {
    deviceId: 'e3dd34cc-5846d795',
    clientName: 'Mobile Follo (Hyundai)',
    coordinates: [10.863207, 59.7139953],
    color: '#1A2C5D',
  },
  {
    deviceId: 'ca216ba1-a30a2d1d',
    clientName: 'Birger N. Haug Gardermoen (Hyundai)',
    coordinates: [11.1529411988385, 60.1673914807139],
    color: '#1A2C5D',
  },
  {
    deviceId: 'ca216b9f-389e0ecf',
    clientName: 'Glommen Bil (Hyundai)',
    coordinates: [11.5749645, 60.1962002],
    color: '#1A2C5D',
  },
  {
    deviceId: 'f8866a6e-c3cddb66',
    clientName: 'Mobile Ensjø (Hyundai)',
    coordinates: [10.790412, 59.915991],
    color: '#1A2C5D',
  },
  {
    deviceId: 'e3ce2f4a-5846d761',
    clientName: 'Sulland Drammen (Hyundai)',
    coordinates: [10.1738495, 59.748334],
    color: '#1A2C5D',
  },
  {
    deviceId: 'ca053c2e-3cf033cb',
    clientName: 'Roa Auto (Hyundai)',
    coordinates: [10.624454, 60.288248],
    color: '#1A2C5D',
  },
  {
    deviceId: '22d0382f-b127b671',
    clientName: 'Brennes Auto (Hyundai)',
    coordinates: [11.0874975, 59.2762372],
    color: '#1A2C5D',
  },
  {
    deviceId: 'e66cb3ee-b127b654',
    clientName: 'Auto 8-8 Rico (Hyundai)',
    coordinates: [6.3813716, 62.4709433],
    color: '#1A2C5D',
  },
  {
    deviceId: '6132eb24-1a18cfc8',
    clientName: 'Strandveien Auto Verdal (Hyundai)',
    coordinates: [11.455636, 63.7681687],
    color: '#1A2C5D',
  },
  {
    deviceId: 'ca216b4f-998f4468',
    clientName: 'Gjermundsen Auto Larvik (Hyundai)',
    coordinates: [10.065411, 59.086775],
    color: '#1A2C5D',
  },
  {
    deviceId: 'c9f6b62e-c9a3325a',
    clientName: 'Birger N. Haug Drammen (Hyundai)',
    coordinates: [10.2594438, 59.7676325],
    color: '#1A2C5D',
  },
  {
    deviceId: 'ca135469-9ca34ee8',
    clientName: 'Auto 8-8 Årø (Hyundai)',
    coordinates: [7.2487871, 62.7453955],
    color: '#1A2C5D',
  },
  {
    deviceId: 'ca2170e7-13f8a05d',
    clientName: 'Birger N. Haug Gardermoen (Hyundai)',
    coordinates: [11.1529411988385, 60.1673914807139],
    color: '#1A2C5D',
  },
  {
    deviceId: 'ca053c7e-7d1cd15a',
    clientName: 'Gol Auto AS (Hyundai)',
    coordinates: [8.9303139, 60.6957558],
    color: '#1A2C5D',
  },
  {
    deviceId: 'ca20fcf3-33e31a20',
    clientName: 'Okkenhaug (Hyundai)',
    coordinates: [10.466325, 63.433425],
    color: '#1A2C5D',
  },
  {
    deviceId: 'ca216c07-cd30f6a5',
    clientName: 'Mobile Skien (Hyundai)',
    coordinates: [9.6167319, 59.1714836],
    color: '#1A2C5D',
  },
  {
    deviceId: 'ca20fcc4-ab8d6e36',
    clientName: 'Lieco Auto (Hyundai)',
    coordinates: [5.1298748, 60.3519986],
    color: '#1A2C5D',
  },
  {
    deviceId: 'e4167b44-af8f0cbe',
    clientName: 'Steinkjer Bil (Hyundai)',
    coordinates: [11.488844, 64.009143],
    color: '#1A2C5D',
  },
  {
    deviceId: 'e682856a-fc1dc7ac',
    clientName: 'Mobile Kjeller (Hyundai)',
    coordinates: [11.0099155, 59.9807674],
    color: '#1A2C5D',
  },
  {
    deviceId: 'ca12e51e-48612b20',
    clientName: 'Fylkesnes Bil (Hyundai)',
    coordinates: [5.4549046, 59.7615241],
    color: '#1A2C5D',
  },
  {
    deviceId: 'ca216c0f-ca506859',
    clientName: 'Fylkesnes Bil (Hyundai)',
    coordinates: [5.4549046, 59.7615241],
    color: '#1A2C5D',
  },
  {
    deviceId: '22d126a0-fc1dc7ae',
    clientName: 'Mobile Sørlandsparken (Hyundai)',
    coordinates: [8.125766, 58.1734413],
    color: '#1A2C5D',
  },
  {
    deviceId: '22d0ac16-af8f0cf8',
    clientName: 'Fagerthun Bil (Hyundai)',
    coordinates: [17.3685227, 68.4226325],
    color: '#1A2C5D',
  },
  {
    deviceId: 'e65db399-5846d1f2',
    clientName: 'Bilalliansen (Hyundai)',
    coordinates: [14.1556144, 66.3107117],
    color: '#1A2C5D',
  },
  {
    deviceId: 'e3ad7494-5846d41c',
    clientName: 'Mobile Sørlandsparken (Hyundai)',
    coordinates: [8.125766, 58.1734413],
    color: '#1A2C5D',
  },
  {
    deviceId: '9f03ff6-fc1dc7a8',
    clientName: 'Mobile Kjeller (Hyundai)',
    coordinates: [11.0099155, 59.9807674],
    color: '#1A2C5D',
  },
  {
    deviceId: 'e3dd34db-5846fd85',
    clientName: 'Bauge Auto (Hyundai)',
    coordinates: [5.3072027, 59.3859118],
    color: '#1A2C5D',
  },
  {
    deviceId: 'e3b85d6d-5846d796',
    clientName: 'Mobile Follo (Hyundai)',
    coordinates: [10.863207, 59.7139953],
    color: '#1A2C5D',
  },
  {
    deviceId: 'e67c2249-5846d413',
    clientName: 'Mobile Sørlandsparken (Hyundai)',
    coordinates: [8.125766, 58.1734413],
    color: '#1A2C5D',
  },
  {
    deviceId: 'e6828591-af8f0cb8',
    clientName: 'Bauge Auto (Hyundai)',
    coordinates: [5.3072027, 59.3859118],
    color: '#1A2C5D',
  },
  {
    deviceId: 'e3ad7483-b127b679',
    clientName: 'Roa Auto Hønefoss (Brænden Motor) (Hyundai)',
    coordinates: [10.2489141159206, 60.1546983696559],
    color: '#1A2C5D',
  },
  {
    deviceId: 'e6647f5a-5846fedc',
    clientName: 'Roa Auto Hønefoss (Brænden Motor) (Hyundai)',
    coordinates: [10.2489141159206, 60.1546983696559],
    color: '#1A2C5D',
  },
  {
    deviceId: 'ca12e560-3edf3770',
    clientName: 'Gol Auto AS (Hyundai)',
    coordinates: [8.9303139, 60.6957558],
    color: '#1A2C5D',
  },
  {
    deviceId: '22e84f35-b127b690',
    clientName: 'Roa Auto Hønefoss (Brænden Motor) (Hyundai)',
    coordinates: [10.2489141159206, 60.1546983696559],
    color: '#1A2C5D',
  },
  {
    deviceId: 'e3ce34bd-fc1dc7c9',
    clientName: 'Bilalliansen (Hyundai)',
    coordinates: [14.1556144, 66.3107117],
    color: '#1A2C5D',
  },
  {
    deviceId: 'b63c428d-8b190caf',
    clientName: 'Nio House Karl Johan',
    coordinates: [59.91396791817756, 10.73874322569007],
    color: '#00bebe',
  },
  {
    deviceId: '83d76f92-8b190cc5',
    clientName: 'Nio House Karl Johan',
    coordinates: [59.91396791817756, 10.73874322569007],
    color: '#00bebe',
  },
  {
    deviceId: '613b8d92-830fa51b',
    clientName: 'Nio House Karl Johan',
    coordinates: [59.91396791817756, 10.73874322569007],
    color: '#00bebe',
  },
  {
    deviceId: '61318803-830fa51c',
    clientName: 'Nio House Karl Johan',
    coordinates: [59.91396791817756, 10.73874322569007],
    color: '#00bebe',
  },
  {
    deviceId: '9f412e9c-8b190c4a',
    clientName: 'Nio House Karl Johan',
    coordinates: [59.91396791817756, 10.73874322569007],
    color: '#00bebe',
  },
  {
    deviceId: '61318dd5-830fa4df',
    clientName: 'Nio Ensjø',
    coordinates: [59.91468702696441, 10.787033887104792],
    color: '#00bebe',
  },
  {
    deviceId: '613b8812-830fa4f5',
    clientName: 'Nio Ensjø',
    coordinates: [59.91468702696441, 10.787033887104792],
    color: '#00bebe',
  },
];
